import { useQuery } from '@tanstack/react-query';
import { useEffect } from 'react';
import { useParams } from 'react-router-dom';

import type { StepType } from 'pages/order-page/ui';
import { Step } from 'pages/order-page/ui';

import { OrderApi } from 'entities/order';

import { onFeServiceError } from 'shared/lib';
import { Spinner } from 'shared/ui';

import pending from './assets/pending.svg';

export const StepProcessing = (props: {
  setStep: (step: StepType) => void;
}) => {
  const { orderId = '' } = useParams<{ orderId: string }>();

  const {
    data: status,
    isError: isStatusError,
    error: statusError,
  } = useQuery({
    queryKey: ['AWAITING_DECISION'],
    queryFn: () => OrderApi.checkAwaitingStatus(orderId, 'AWAITING_DECISION'),
  });

  useEffect(() => {
    if (isStatusError) {
      onFeServiceError(statusError as any, () => props.setStep(Step.Failed));
    }

    if (status === 'APPROVED') {
      props.setStep(Step.Success);
    }

    if (status === 'REJECTED') {
      props.setStep(Step.Failed);
    }
  }, [status, isStatusError]);

  return (
    <>
      <p className=" text-center text-xl font-normal md:text-3xl">
        Ожидаем решения по заявке
      </p>
      <div
        className="relative mx-auto size-[168px] rounded-full"
        style={{
          backgroundImage: `url(${pending})`,
        }}
      >
        <Spinner className="absolute inset-0" />
      </div>
      <p className="mx-auto max-w-[80%] text-center text-xl font-normal leading-[120%] md:text-2xl">
        Рассмотрение заявки, пожалуйста, подождите. <br /> <br />
        Ваш заказ автоматически оформится в случае положительного решения
      </p>
    </>
  );
};
