import { AxiosError } from 'axios';
import { UUID } from 'uuid-generator-ts';

import { useQuery } from '@tanstack/react-query';
import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';

import { ApprovalCode } from 'pages/order-page/ui/steps/model';

import { OrderApi } from 'entities/order';

import { ApprovalCodeKey, XSessionIdKey } from 'shared/contants';
import { onFeServiceError } from 'shared/lib';
import { Spinner } from 'shared/ui';

import type { StepType } from './ui/steps';
import { Step, Steps } from './ui/steps';

sessionStorage.setItem(XSessionIdKey, UUID.createUUID());
sessionStorage.setItem(ApprovalCodeKey, ApprovalCode.ORDER_SIGN);

export const OrderPage = () => {
  const { orderId = '' } = useParams<{ orderId: string }>();
  const [step, setStep] = useState<StepType | null>(null);

  const { data, isError, error } = useQuery({
    queryKey: [orderId],
    queryFn: () => OrderApi.getProcessingState(orderId),
  });

  useEffect(() => {
    if (isError && error instanceof AxiosError) {
      onFeServiceError(error as any, () => setStep(Step.Failed));
    }
  }, [isError]);

  useEffect(() => {
    switch (data) {
      case 'START':
        setStep(Step.Enrichment);
        return;
      case 'APPROVED':
        setStep(Step.Success);
        return;
      case 'AWAITING_PAYMENT':
        setStep(Step.Payment);
        return;
      case 'AWAITING_DECISION':
        setStep(Step.Processing);
        return;
      case 'REJECTED':
        setStep(Step.Failed);
        return;
    }
  }, [data]);

  if (!data || isError) {
    return (
      <div className="m-auto flex h-screen min-w-[400px] max-w-[576px] py-10 md:w-fit">
        <div className="grow-1 relative grid size-full h-fit min-h-full min-w-[400px] overflow-auto rounded-2xl bg-white p-8 md:w-fit">
          <Spinner />
        </div>
      </div>
    );
  }

  return (
    <div className="m-auto flex h-screen w-full py-10">
      <div className="grow-1 m-auto grid size-full items-center overflow-auto bg-white p-8 md:w-[601px]">
        {data && step === Step.Enrichment && (
          <Steps.Enrichment setStep={setStep} />
        )}
        {data && step === Step.Confirmation && (
          <Steps.Confirmation setStep={setStep} />
        )}
        {data && step === Step.Payment && <Steps.Payment setStep={setStep} />}
        {data && step === Step.Processing && (
          <Steps.Processing setStep={setStep} />
        )}
        {data && step === Step.Failed && <Steps.Failed />}
        {data && step === Step.Success && <Steps.Success />}
      </div>
    </div>
  );
};
