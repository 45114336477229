import { Button } from 'shared/ui';
import { ButtonColored } from 'shared/ui/button';
import { Logo } from 'shared/ui/logo';

export const MainPage = () => {
  return (
    <div className="h-fit bg-white">
      <nav className="mx-auto flex h-20 max-w-screen-2xl items-center justify-between border-gray-200">
        <div className="flex items-center gap-4">
          <Logo />
          <div className="hidden w-full items-center justify-between md:flex md:w-auto">
            <ul className="md:0 mt-4 flex flex-col rounded-lg border border-gray-100 bg-gray-50 p-4 font-medium md:mt-0 md:flex-row md:space-x-8 md:border-0 md:bg-white md:p-0 rtl:space-x-reverse">
              {[
                { text: 'О нас', link: '/' },
                { text: 'Партнерам', link: '/' },
                { text: 'FAQ', link: '/' },
              ].map((item) => (
                <li>
                  <a
                    href={item.link}
                    className="block rounded px-3 py-2  text-brand-1 hover:underline"
                    aria-current="page"
                  >
                    {item.text}
                  </a>
                </li>
              ))}
            </ul>
          </div>
        </div>
        <div className="flex items-center gap-1">
          <Button>Войти</Button>
          <ButtonColored>Регистрация</ButtonColored>
        </div>
      </nav>
    </div>
  );
};
