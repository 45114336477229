import { HomeButton } from 'pages/order-page/ui';

import error from './assets/error.svg';

export const StepFailed = () => {
  const { errorMessage }: { errorCode: number; errorMessage: string } =
    localStorage.getItem('errorMessage') &&
    JSON.parse(localStorage.getItem('errorMessage')!);

  return (
    <>
      <p className="text-center text-xl font-normal md:text-3xl">
        {!!errorMessage ? 'Ошибка' : 'Отказ'}
      </p>
      <div
        className="mx-auto size-[168px] rounded-full"
        style={{
          backgroundImage: `url(${error})`,
        }}
      />
      <p className="mx-auto max-w-[80%] text-center text-xl font-normal leading-[120%] md:text-2xl">
        {!!errorMessage
          ? errorMessage
          : 'К сожалению, мы не можем утвердить вашу заявку'}
      </p>
      <HomeButton />
    </>
  );
};
