import { HomeButton } from 'pages/order-page/ui';

import success from './assets/success.svg';

export const StepSuccess = () => {
  return (
    <>
      <p className="text-center text-xl font-normal md:text-3xl">
        Спасибо за покупку!
      </p>
      <div
        className="mx-auto size-[168px] rounded-full "
        style={{
          backgroundImage: `url(${success})`,
        }}
      />
      <p className="mx-auto max-w-[80%] text-center text-xl font-normal leading-[120%] md:text-2xl">
        Ваш заказ принят и находится в обработке
      </p>
      <HomeButton />
    </>
  );
};
