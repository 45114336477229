import { cx } from '@emotion/css';

import { Link } from 'react-router-dom';

import { ButtonColored } from 'shared/ui/button';

export const HomeButton = () => {
  return (
    <ButtonColored className="mx-auto">
      <Link to="/" className={cx(' text-white ')}>
        На главную
      </Link>
    </ButtonColored>
  );
};
