import { cx } from '@emotion/css';

import { yupResolver } from '@hookform/resolvers/yup';
import { AxiosError } from 'axios';
import { format } from 'date-fns';
import * as yup from 'yup';
import { InferType } from 'yup';

import { Field, Input, Label } from '@headlessui/react';
import { useQuery } from '@tanstack/react-query';
import { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useParams } from 'react-router-dom';

import {
  ApprovalCode,
  DatepickerSimple,
  Step,
  StepType,
} from 'pages/order-page/ui';

import { ButtonDownloadAgreementPurchase } from 'features/download-agreement-purchase/ui';
import { ButtonDownloadPersonalData } from 'features/download-personal-data/ui';

import { OrderApi } from 'entities/order';

import { ApprovalCodeKey } from 'shared/contants';
import { Spinner } from 'shared/ui';
import { ButtonColored } from 'shared/ui/button';
import { Logo } from 'shared/ui/logo';

const emailRegex = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i;

const schema = yup
  .object({
    first_name: yup
      .string()
      .trim()
      .min(4, 'Минимум 4 символа')
      .required('Обязательное поле'),
    middle_name: yup
      .string()
      .trim()
      .min(4, 'Минимум 4 символа')
      .required('Обязательное поле'),
    last_name: yup
      .string()
      .trim()
      .min(4, 'Минимум 4 символа')
      .required('Обязательное поле'),
    birth_date: yup.string().required('Обязательное поле'),
    email: yup.string().trim().required('Обязательное поле'),
  })
  .shape({
    email: yup
      .string()
      .matches(emailRegex, 'Почта должна иметь валидный формат')
      .required('Обязательное поле'),
  });

export const StepEnrichment = (props: {
  setStep: (step: StepType) => void;
}) => {
  const [enabledAgreementPurchase, setEnabledAgreementPurchase] =
    useState(true);
  const [enabledPersonalData, setEnabledPersonalData] = useState(true);

  const { orderId = '' } = useParams<{ orderId: string }>();
  const approvalCode = sessionStorage.getItem(ApprovalCodeKey);

  const {
    setValue,
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    mode: 'onSubmit',
    resolver: yupResolver(schema),
  });

  const { data, isLoading, isError, error } = useQuery({
    queryKey: ['initial', approvalCode],
    queryFn: () =>
      approvalCode === ApprovalCode.ORDER_SIGN
        ? OrderApi.checkRepeatedClient(orderId)
        : OrderApi.orderRequest(orderId),
  });

  useEffect(() => {
    if (!isLoading && !isError && typeof data === 'boolean' && !!data) {
      props.setStep(Step.Confirmation);
      sessionStorage.setItem(ApprovalCodeKey, ApprovalCode.NUMBER_CONFIRMATION);
    }

    if (!isLoading && !isError && typeof data === 'object' && !!data) {
      setValue('first_name', data.person_response.first_name);
      setValue('last_name', data.person_response.last_name);
      setValue('middle_name', data.person_response.middle_name);
      setValue('birth_date', data.person_response.birth_date);
      setValue('email', data.person_response.email);
    }

    if (isError && error instanceof AxiosError) {
      localStorage.setItem(
        'errorMessage',
        JSON.stringify(error.response!.data),
      );
      props.setStep(Step.Failed);
      return;
    }
  }, [data, isLoading, isError]);

  const onSubmit = (data: InferType<typeof schema>) => {
    sessionStorage.setItem('user_data', JSON.stringify(data));
    sessionStorage.setItem(ApprovalCodeKey, ApprovalCode.ORDER_SIGN);
    props.setStep(Step.Confirmation);
  };

  if (isLoading || !data) {
    return <Spinner />;
  }

  return (
    <>
      <div className="flex w-full max-w-full flex-col  gap-4 overflow-hidden">
        <Logo />
        <div className="flex max-w-full items-center gap-2 overflow-x-auto">
          {typeof data === 'object' &&
            (data.payment_plan.length === 4
              ? [100, 75, 50, 25]
              : [100, 75, 50, 25, 10.5, 5]
            ).map((opacity, index) => (
              <span
                className="flex w-full flex-col gap-1 text-xs"
                key={opacity}
              >
                <span
                  className={cx(`bg-brand-main-color h-2 rounded-3xl w-full`)}
                  style={{
                    opacity: `${opacity / 100}`,
                  }}
                />
                <span>{data.payment_plan[index]?.date}</span>
                <span> {data.payment_plan[index]?.sum} ₽</span>
              </span>
            ))}
        </div>
      </div>
      <form
        onSubmit={handleSubmit(onSubmit)}
        className="flex flex-col justify-between gap-6 self-start"
      >
        <div>
          <Field className="pt-2">
            <Label className="hidden text-sm/6 font-medium text-white">
              Фамилия
            </Label>
            <Input
              placeholder="Фамилия"
              {...register('last_name')}
              className={cx(
                'w-full rounded-lg border border-[#575757] flex items-center h-14 hover:border-brand-1 py-1.5 px-3 text-sm',
                'focus:outline-none',
              )}
            />
            {errors['last_name'] && (
              <span className="mt-1 text-sm/6 font-medium text-rose-400">
                {errors['last_name']?.message}
              </span>
            )}
          </Field>
          <Field className="pt-2">
            <Label className="hidden text-sm/6 font-medium text-white">
              Имя
            </Label>
            <Input
              {...register('first_name')}
              placeholder="Имя"
              className={cx(
                'w-full rounded-lg border border-[#575757] flex items-center h-14 hover:border-brand-1 py-1.5 px-3 text-sm',
                'focus:outline-none',
              )}
            />
            {errors['first_name'] && (
              <span className="mt-1 text-sm/6 font-medium text-rose-400">
                {errors['first_name']?.message}
              </span>
            )}
          </Field>
          <Field className="pt-2">
            <Label className="hidden text-sm/6 font-medium text-white">
              Отчество
            </Label>
            <Input
              {...register('middle_name')}
              placeholder="Отчество"
              className={cx(
                'w-full rounded-lg border border-[#575757] flex items-center h-14 hover:border-brand-1 py-1.5 px-3 text-sm',
                'focus:outline-none',
              )}
            />
            {errors['middle_name'] && (
              <span className="mt-1 text-sm/6 font-medium text-rose-400">
                {errors['middle_name']?.message}
              </span>
            )}
          </Field>
          <Field className="pt-2">
            <Label className="hidden text-sm/6 font-medium text-white">
              Отчество
            </Label>
            <Input
              {...register('email')}
              placeholder="E-mail"
              className={cx(
                'w-full rounded-lg border border-[#575757] flex items-center h-14 hover:border-brand-1 py-1.5 px-3 text-sm',
                'focus:outline-none',
              )}
            />
            {errors['email'] && (
              <span className="mt-1 text-sm/6 font-medium text-rose-400">
                {errors['email']?.message}
              </span>
            )}
          </Field>
          <Field className="pt-2">
            <Label className="hidden text-sm/6 font-medium text-white">
              Дата рождения
            </Label>
            <DatepickerSimple
              // defaultValue={data?.person_response?.birth_date}
              onChange={(date) =>
                setValue('birth_date', format(String(date), 'yyyy.MM.dd'))
              }
            />
            {errors['birth_date'] && (
              <span className="mt-1 text-sm/6 font-medium text-rose-400">
                {errors['birth_date']?.message}
              </span>
            )}
          </Field>
        </div>
        <div>
          <ButtonDownloadAgreementPurchase
            enabled={enabledAgreementPurchase}
            setEnabled={setEnabledAgreementPurchase}
            orderId={orderId}
          />
          <ButtonDownloadPersonalData
            enabled={enabledPersonalData}
            setEnabled={setEnabledPersonalData}
            orderId={orderId}
          />
        </div>
        <ButtonColored
          disabled={!enabledAgreementPurchase || !enabledPersonalData}
        >
          Подтвердить
        </ButtonColored>
      </form>
    </>
  );
};
