import './styles/main.scss';

import { ConfigProvider } from 'antd';
import { MainPage, OrderPage } from 'pages';

import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';

import { RoutesPath } from 'shared/routes-path';

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
      refetchOnMount: false,
      refetchOnReconnect: false,
      retry: false,
    },
  },
});

export const App = () => {
  return (
    <QueryClientProvider client={queryClient}>
      <ConfigProvider
        theme={{
          token: {
            colorPrimary: '#991e66',
          },
        }}
      >
        <BrowserRouter>
          <Routes>
            <Route path="/" element={<MainPage />} />
            <Route path={RoutesPath.ORDER} element={<OrderPage />} />
          </Routes>
          <ToastContainer closeOnClick theme="colored" />
        </BrowserRouter>
      </ConfigProvider>
    </QueryClientProvider>
  );
};
