import { cx } from '@emotion/css';

import { yupResolver } from '@hookform/resolvers/yup';
import { AxiosError } from 'axios';
import * as yup from 'yup';
import { InferType } from 'yup';

import { useMutation, useQuery } from '@tanstack/react-query';
import { Digit, DigitInputs } from 'digitinputs-react';
import { useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { useParams } from 'react-router-dom';

import type { StepType } from 'pages/order-page/ui';
import { ApprovalCode, Step } from 'pages/order-page/ui';

import { OrderApi } from 'entities/order';

import { onFeServiceError } from 'shared/lib';
import { Spinner } from 'shared/ui';
import { ButtonColored } from 'shared/ui/button';

const schema = yup.object({
  code: yup.string().required(),
});

export const StepConfirmation = (props: {
  setStep: (step: StepType) => void;
}) => {
  const { orderId = '' } = useParams<{ orderId: string }>();
  const approvalCode = sessionStorage.getItem('approval-code');
  const { setValue, handleSubmit } = useForm({
    mode: 'onSubmit',
    resolver: yupResolver(schema),
  });

  const { data, isError, error } = useQuery({
    queryKey: ['sendSms', approvalCode],
    queryFn: () => OrderApi.sendSms(orderId, approvalCode!),
  });

  useEffect(() => {
    if (isError) {
      onFeServiceError(error as any, () => props.setStep(Step.Failed));
    }
  }, [isError]);

  const sendMutation = useMutation({
    mutationKey: ['checkSms', approvalCode],
    mutationFn: (data: { code: number }) =>
      OrderApi.checkSms({
        orderId,
        code: data.code as unknown as string,
        approval_code_type: approvalCode!,
      }),
    onSuccess: ({ success }) => {
      if (success) {
        if (approvalCode === ApprovalCode.NUMBER_CONFIRMATION) {
          props.setStep(Step.Enrichment);
          return;
        }

        const user_data =
          sessionStorage.getItem('user_data') &&
          JSON.parse(sessionStorage.getItem('user_data')!);

        enrichmentMutation.mutate({ orderId, ...user_data });
      }
    },
    onError: (error: AxiosError) => {
      onFeServiceError(error as any, () => props.setStep(Step.Failed));
    },
  });

  const enrichmentMutation = useMutation({
    mutationKey: ['checkSms', approvalCode],
    mutationFn: (data: any) => OrderApi.enrichment(data),
    onSuccess: (data) => {
      window.open(data, '_blank');
      props.setStep(Step.Payment);
    },
    onError: (error: AxiosError) => {
      onFeServiceError(error as any, () => props.setStep(Step.Failed));
    },
  });

  const onSubmit = (data: InferType<typeof schema>) => {
    // @ts-ignore
    sendMutation.mutate(data);
  };

  if (!data) {
    return <Spinner />;
  }

  return (
    <>
      <p className="my-4 flex flex-col text-justify text-xl font-normal md:text-2xl">
        <span className="text-center">Введите код из смс</span>
        <span className="pt-2 text-center text-xs text-gray-400">
          Введите, пожалуйста, код из смс и нажмите «Подтвердить»
        </span>
      </p>
      <form
        onSubmit={handleSubmit(onSubmit)}
        className="flex flex-col justify-between gap-6 self-start"
      >
        <div className="my-4 text-center">
          <div className="flex justify-center gap-2">
            <DigitInputs
              className="mb-6 flex justify-center gap-[16px]"
              onDigitsChange={(value: any) => setValue('code', value?.asNumber)}
            >
              <Digit
                className={cx(
                  'rounded-lg border border-[#575757] flex items-center h-14 w-12 hover:border-brand-1 py-3 px-3 text-sm',
                  'focus:outline-none',
                )}
              />
              <Digit
                className={cx(
                  'rounded-lg border border-[#575757] flex items-center h-14 w-12 hover:border-brand-1 py-3 px-3 text-sm',
                  'focus:outline-none',
                )}
              />
              <Digit
                className={cx(
                  'rounded-lg border border-[#575757] flex items-center h-14 w-12 hover:border-brand-1 py-3 px-3 text-sm',
                  'focus:outline-none',
                )}
              />
              <Digit
                className={cx(
                  'rounded-lg border border-[#575757] flex items-center h-14 w-12 hover:border-brand-1 py-3 px-3 text-sm',
                  'focus:outline-none',
                )}
              />
            </DigitInputs>
          </div>
          <span className="mt-4 pt-2 text-xs underline">
            Отправить код повторно
          </span>
        </div>
        <ButtonColored>Подтвердить</ButtonColored>
      </form>
    </>
  );
};
